import React from 'react';
import { Box, Stack, Typography, Chip, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

type PricingAndSubscribeProps = {
    highlightPlanPrice?: boolean;
    planPrice?: string;
    isTailored?: boolean;
    showPerMonth?: boolean;
    chipTitle?: string;
    defaultChipTitle?: string;
    showChip?: boolean;
    showSubscribeButton?: boolean;
    onSubscribeButtonClick?: () => void;
    subscribeButtonType?: 'contact' | string; // Add other types as needed
    subscribeButtonTitle?: string;
    defaultSubscribeButtonTitle?: string;
    disabled?: boolean;
};
export const PricingAndSubscribe: React.FC<PricingAndSubscribeProps> = ({
    highlightPlanPrice = false, // set default value if needed
    planPrice = '',
    isTailored = false,
    showPerMonth = false,
    chipTitle,
    defaultChipTitle = '',
    showChip = false,
    showSubscribeButton = false,
    onSubscribeButtonClick,
    subscribeButtonType = 'contact',
    subscribeButtonTitle,
    defaultSubscribeButtonTitle = '',
    disabled = false,
}) => {
    const { t } = useTranslation();

    return (
        <>
            {/* Pricing section */}
            <Box height={'75px'}>
                <Stack
                    direction='row'
                    alignItems='flex-end'
                    sx={{
                        height: '15%',
                        justifyContent: 'left',
                        paddingBottom: '10px',
                        minHeight: '70px',
                    }}>
                    <Typography variant='h5' color={highlightPlanPrice ? 'secondary' : 'info'}>
                        {planPrice}
                        {showPerMonth ? t('pricing.per-month') : ''}
                    </Typography>
                    {showPerMonth ? <Typography variant='caption'>{/* /{t('pricing.per-month')} */}</Typography> : <></>}
                </Stack>
            </Box>
            {/* Subscribe Button section */}
            <Box height={'100px'}>
                <Stack sx={{ height: '15%', borderRadius: 4, minHeight: '70px' }}>
                    {!isTailored && showChip ? (
                        <Box height='20px'>
                            <Chip style={{ width: '100%', fontSize: '12px' }} label={chipTitle || defaultChipTitle}></Chip>
                        </Box>
                    ) : (
                        <Box height='20px'>
                            <Chip
                                style={{
                                    width: '100%',
                                    fontSize: '12px',
                                    backgroundColor: 'transparent',
                                }}></Chip>
                        </Box>
                    )}
                    <br />
                    {showSubscribeButton ? (
                        // <Box height='100px'>
                        <Button disabled={disabled} style={{ height: '50px' }} variant='contained' onClick={onSubscribeButtonClick} color={subscribeButtonType == 'contact' ? 'info' : 'inherit'}>
                            {subscribeButtonTitle || defaultSubscribeButtonTitle}
                        </Button>
                    ) : (
                        // <Box />
                        <Box height='50px' />
                    )}
                </Stack>
            </Box>
        </>
    );
};
