import { gql, useLazyQuery } from '@apollo/client';
import { Constants } from 'Constants';

import { SlideComponentFillInTheBlank, SlideComponentFillInTheBlankQuestionModel } from 'models/lesson/LessonModel';

const { v4: uuidv4 } = require('uuid');

const marginLeft = 100;
const marginTop = 200;

type SentenceModel = {
    answerText: string;
    questionText: string;
};

type GenerateTextQueryModel = {
    generateTextFillInTheBlank: SentenceModel[];
};

const GENERATE_TEXT_QUERY = gql`
    query GenText($questionTopic: String!, $questionLength: String!) {
        generateTextFillInTheBlank(questionTopic: $questionTopic, questionLength: $questionLength) {
            answerText
            questionText
        }
    }
`;

const GENERATE_TEXT_GENERIC_QUERY = gql`
    query GenTextGeneric($prompt: String!) {
        generateTextGeneric(prompt: $prompt) {
            replyText
        }
    }
`;

export const useLessonsSlidePropsFillInTheBlankHook = (itemContent: SlideComponentFillInTheBlank) => {
    const [genText, { loading }] = useLazyQuery<GenerateTextQueryModel>(GENERATE_TEXT_QUERY, { fetchPolicy: 'no-cache' });
    const getTextFromServer = async (questionLength: string, questionTopic: string, isRegenerate: boolean) => {
        if (!isRegenerate && itemContent.questions.length > 0) {
            return;
        }
        try {
            const result = await genText({
                variables: { questionLength, questionTopic },
            });
            UpdateQuestionArrayInfo(result.data?.generateTextFillInTheBlank ?? []);
        } catch (err) {
            console.log('gen text error:', err);
        }
    };

    const getTextFromServer_flexible = async (questionLength: string, questionTopic: string, isRegenerate: boolean) => {
        if (!isRegenerate && itemContent.questions.length > 0) {
            return;
        }
        try {
            const result = await genText({
                variables: { questionLength, questionTopic },
            });
            UpdateQuestionArrayInfo(result.data?.generateTextFillInTheBlank ?? []);
        } catch (err) {
            console.log('gen text error:', err);
        }
    };

    const UpdateQuestionArrayInfo = (data: SentenceModel[]) => {
        let newQuestion: SlideComponentFillInTheBlankQuestionModel[] = [];
        let i = 0;
        for (const question of data) {
            newQuestion.push({
                id: uuidv4(),
                answerText: question.answerText,
                questionText: question.questionText,
                sort: 0,
            });
            i++;
            if (i >= Constants.lesson.fillInTheBlankQuestionLimit) {
                break;
            }
        }
        itemContent.questions = newQuestion;
    };
    const deleteQuestionArrayInfo = (idx: number) => {
        const indexToDelete = idx; // delete the item at index 1
        const newQuestion = itemContent.questions.filter((item, index) => index !== indexToDelete);
        itemContent.questions = newQuestion;
    };

    const addQuestion = () => {
        let currentIdx = itemContent.questions.length;
        itemContent.questions.push({
            id: uuidv4(),
            answerText: '',
            questionText: '',
            sort: 0,
        });
    };
    const questionFieldChange = (idx: number, textContent: string) => {
        itemContent.questions[idx].questionText = textContent;
    };
    const answerFieldChange = (idx: number, textContent: string) => {
        itemContent.questions[idx].answerText = textContent;
    };
    return {
        getTextFromServer,
        deleteQuestionArrayInfo,
        questionFieldChange,
        answerFieldChange,
        loading,
        hasGenerated: itemContent.questions.length > 0,
        addQuestion,
    };
};
