import React from 'react';
import { Stack, Button, Box, Typography, Divider, useTheme, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CheckCircle, CheckCircleOutlineOutlined, Height } from '@mui/icons-material';
import { PricingAndSubscribe } from './components/PricingAndSubscribe'; // Adjust the path as necessary

type PaymentPlanCardProps = {
    showBottomControls?: boolean;
    showPopularPlan: boolean;
    chipTitle?: string;
    subscribeButtonTitle?: string;
    subscribeButtonType?: 'subscribe' | 'contact';
    planTitle: string;
    planPrice: string;
    planDetails: string | React.ReactNode;
    highlightPlanPrice?: boolean;
    popularPlanLabel?: string;
    isTailored?: boolean;
    showPerMonth?: boolean;
    showChip?: boolean;
    showSubscribeButton?: boolean;
    minWidth?: number;
    disabled?: boolean;
    onSubscribeButtonClick?: () => void;
};

export const PaymentPlanCard = ({
    showBottomControls = false,
    showPopularPlan,
    minWidth = 0,
    showPerMonth = true,
    showChip = false,
    // showSubscribeButton = true,
    showSubscribeButton = false,
    subscribeButtonType = 'subscribe',
    chipTitle,
    subscribeButtonTitle,
    planTitle,
    planPrice,
    planDetails,
    highlightPlanPrice = false,
    popularPlanLabel,
    isTailored,
    disabled = false,
    onSubscribeButtonClick,
}: PaymentPlanCardProps) => {
    const { t } = useTranslation();

    const defaultChipTitle = '';
    const defaultSubscribeButtonTitle = t('Subscribe');
    const translatedPopularPlanLabel = popularPlanLabel ? t(popularPlanLabel) : '';

    const theme = useTheme();
    const maxFontSize = theme.typography.h5.fontSize;

    // Calculate the actual font size based on the available width
    const calculateFontSize = (text: string, maxWidth: number) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (context) {
            context.font = maxFontSize as string; // Explicitly cast maxFontSize to string
            const textWidth = context.measureText(text).width;
            const scaleFactor = maxWidth / textWidth;
            const fontSize = scaleFactor * parseFloat(maxFontSize as string); // Explicitly cast maxFontSize to string
            return `${fontSize}px`;
        }
        return maxFontSize;
    };

    return (
        <Stack
            direction='column'
            sx={{
                // maxWidth: 368,
                width: 368,
                minWidth: minWidth,
                minHeight: 1107,
                backgroundColor: '#ffffff',
                borderRadius: '4px',
                border: '1px solid #e0e0e0',
                boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
                position: 'relative',
            }}>
            {showPopularPlan && (
                <Box
                    sx={{
                        height: '45px',
                        backgroundColor: '#A021AD',
                        color: '#ffffff',
                        // padding: '4px 0px',
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '4px',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        textAlign: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        paddingTop: '5px',
                    }}>
                    <Stack direction='row'>
                        <Box sx={{ marginLeft: '10px' }}>
                            <CheckCircle />
                        </Box>
                        <Box sx={{ marginLeft: '20px' }}>{translatedPopularPlanLabel}</Box>
                    </Stack>
                </Box>
            )}
            <Stack
                sx={{
                    height: 'calc(100% - 20px)',
                    padding: '30px',
                    position: 'relative',
                    marginTop: '20px',
                }}>
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'left',
                        // width: 368,
                        height: 64,
                    }}>
                    <Typography
                        variant='h5'
                        sx={{
                            fontSize: 'h5',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            padding: 0,
                            // width: 336,
                            height: 32,
                        }}>
                        {planTitle}
                    </Typography>
                </Stack>
                <Box sx={{ height: '10px' }}>
                    {showSubscribeButton ? (
                        <Divider
                            sx={{
                                marginTop: '8px',
                                marginBottom: '8px',
                                marginLeft: '-30px',
                                marginRight: '-30px',
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </Box>
                <Stack direction='column' sx={{ height: '88.86%' }}>
                    <PricingAndSubscribe
                        highlightPlanPrice={highlightPlanPrice}
                        planPrice={planPrice}
                        showPerMonth={!isTailored && planPrice != ''}
                        chipTitle={chipTitle}
                        showChip={showChip}
                        isTailored={isTailored}
                        showSubscribeButton={showSubscribeButton}
                        onSubscribeButtonClick={onSubscribeButtonClick}
                        subscribeButtonTitle={subscribeButtonTitle}
                        subscribeButtonType={subscribeButtonType}
                        disabled={disabled}
                    />
                    {/* Plan details section */}
                    <Stack>{planDetails}</Stack>

                    {showBottomControls ? (
                        <>
                            <PricingAndSubscribe
                                highlightPlanPrice={highlightPlanPrice}
                                planPrice={planPrice}
                                showPerMonth={!isTailored && planPrice != ''}
                                chipTitle={chipTitle}
                                showChip={showChip}
                                isTailored={isTailored}
                                showSubscribeButton={showSubscribeButton}
                                onSubscribeButtonClick={onSubscribeButtonClick}
                                subscribeButtonTitle={subscribeButtonTitle}
                                subscribeButtonType={subscribeButtonType}
                                disabled={disabled}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};
