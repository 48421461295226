import { gql, useLazyQuery } from '@apollo/client';

import { SlideComponentMultipleChoice, SlideComponentMultipleChoiceQuestionModel } from 'models/lesson/LessonModel';

const { v4: uuidv4 } = require('uuid');

const marginLeft = 100;
const marginTop = 200;

type SentenceModel = {
    answerText: string;
    questionText: string;
};

type MultpleChoiceQuestionModel = {
    questionText: string;
    choices: string[];
    answerIndex: number;
};

type GenerateTextQueryModel = {
    generateTextFillInTheBlank: SentenceModel[];
};

const GENERATE_TEXT_QUERY = gql`
    query GenText($questionTopic: String!, $questionLength: String!) {
        generateTextFillInTheBlank(questionTopic: $questionTopic, questionLength: $questionLength) {
            answerText
            questionText
        }
    }
`;

const GENERATE_TEXT_GENERIC_QUERY = gql`
    query GenTextGeneric($questionPrompt: String!) {
        generateTextGeneric(questionPrompt: $questionPrompt) {
            replyText
        }
    }
`;

export const useLessonsSlidePropsMultipleChoiceHook = (itemContent: SlideComponentMultipleChoice) => {
    const [genText, { loading: genTextLoading }] = useLazyQuery<GenerateTextQueryModel>(GENERATE_TEXT_QUERY, {
        fetchPolicy: 'no-cache',
    });

    const [genTextGeneric, { loading }] = useLazyQuery(GENERATE_TEXT_GENERIC_QUERY, {
        fetchPolicy: 'no-cache',
    });

    const parseResultsFillInTheBlank = (results: string) => {
        console.log('Parsing results continued...');
        const regex = /(.+):(.+)/g;
        let match;
        const sentences: any = [];
        while ((match = regex.exec(results)) !== null) {
            sentences.push({
                answerText: match[1],
                questionText: match[2],
            });
            // console.log(`Word: ${match[1]}, Sentence: ${match[2]}`);
        }
        return sentences; // Return the parsed sentences
    };

    const parseResultsMultipleChoice = (results: string) => {
        const blocks = results.split(/\n\s*\n/); // Split the text into blocks using blank lines as separators

        const parsedBlocks = blocks.map((block: string) => {
            const questionMatch = block.match(/^.+/);
            const questionText = questionMatch ? questionMatch[0].trim() : '';

            const choicesRegex = /[A-D]\)\s(.*)(?=(?:\n(?!答案：)[A-D])|\n答案：)/g;
            const choicesMatch = block.match(choicesRegex);
            const choices = choicesMatch ? choicesMatch.map((choice) => choice.replace(/[A-D]\)\s/, '').trim()) : [];

            const answerRegex = /答案：([A-D])\)/;
            const answerMatch = block.match(answerRegex);
            const answerIndex = answerMatch ? answerMatch[1].charCodeAt(0) - 'A'.charCodeAt(0) : -1;

            return { questionText, choices, answerIndex };
        });

        // console.log(parsedBlocks);
        return parsedBlocks;
    };

    const getTextFromServerGeneric = async (questionType: string, questionPrompt: string, isRegenerate: boolean) => {
        // console.log(
        //   'inside useLessonsSlidePropsMultipleChoiceHook -> getTextFromServerGeneric',
        //   itemContent.questions,
        //   questionType,
        // );
        if (!isRegenerate && itemContent.questions.length > 0) {
            return;
        }
        try {
            const result = await genTextGeneric({
                variables: { questionPrompt },
            });

            // console.log(result.data.generateTextGeneric.replyText);

            if (questionType == 'multipleChoice') {
                let blocks = parseResultsMultipleChoice(result.data.generateTextGeneric.replyText);
                // 1. 這個筆盒的尺寸是多少？
                // A) 十厘米長、五厘米闊、三厘米高
                // B) 十厘米長、十厘米闊、五厘米高
                // C) 二十厘米長、十厘米闊、五厘米高
                // D) 二十厘米長、五厘米闊、十厘米高
                // 答案：C) 二十厘米長、十厘米闊、五厘米高
                UpdateQuestionArrayInfoMultipleChoice(blocks ?? []);
            }
        } catch (err) {
            console.log('gen text error:', err);
        }
    };

    const UpdateQuestionArrayInfoMultipleChoice = (data: MultpleChoiceQuestionModel[]) => {
        // console.log('Inside UpdateQuestionArrayInfoMultipleChoice: ', data);
        let newQuestion: SlideComponentMultipleChoiceQuestionModel[] = [];
        let i = 0;
        for (const question of data) {
            newQuestion.push({
                id: uuidv4(),
                choices: question.choices,
                answerIndex: question.answerIndex,
                questionText: question.questionText,
                pos: {
                    x: marginLeft + 400 * (i % 2),
                    y: marginTop + 100 * Math.floor(i / 2),
                },
            });
            i++;
        }

        itemContent.questions = newQuestion;
    };

    const deleteQuestionArrayInfo = (idx: number) => {
        const indexToDelete = idx; // delete the item at index 1
        const newQuestion = itemContent.questions.filter((item, index) => index !== indexToDelete);
        itemContent.questions = newQuestion;
    };

    const addQuestion = () => {
        let currentIdx = itemContent.questions.length;
        itemContent.questions.push({
            id: uuidv4(),
            answerIndex: 0,
            questionText: '',
            choices: ['', '', '', ''],
            pos: {
                x: marginLeft + 400 * (currentIdx % 2),
                y: marginTop + 100 * Math.floor(currentIdx / 2),
            },
        });
    };

    const addChoice = (questionIndex: number) => {
        if (itemContent.questions[questionIndex].choices.length < 5) {
            itemContent.questions[questionIndex].choices.push(''); // Add an empty string as a new choice
        }
    };

    const deleteChoice = (questionIndex: number, choiceIndex: number) => {
        if (itemContent.questions[questionIndex].choices.length > 2) {
            itemContent.questions[questionIndex].choices = itemContent.questions[questionIndex].choices.filter((_, index) => index !== choiceIndex);
        }
    };

    const questionFieldChange = (idx: number, textContent: string) => {
        // console.log('choicesFieldChange: ', textContent);

        itemContent.questions[idx].questionText = textContent;
    };
    const answerIndexChange = (idx: number, myAnswerIndex: number) => {
        // console.log(itemContent);
        itemContent.questions[idx].answerIndex = myAnswerIndex;
    };
    const choicesFieldChange = (idx: number, choiceIndex: number, choiceContent: string) => {
        // console.log('choicesFieldChange: ', choiceIndex, choiceContent);
        itemContent.questions[idx].choices[choiceIndex] = choiceContent;
        // console.log(itemContent.questions[idx]);
    };
    // const answerFieldChange = (idx: number, myAnswerIndex: number) => {
    //   itemContent.questions[idx].answerIndex = myAnswerIndex;
    //   updateUI();
    // };
    return {
        getTextFromServerGeneric,
        deleteQuestionArrayInfo,
        questionFieldChange,
        // answerFieldChange,
        choicesFieldChange,
        answerIndexChange,
        loading,
        hasGenerated: itemContent.questions.length > 0,
        addQuestion,
        addChoice,
        deleteChoice,
    };
};
