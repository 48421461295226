import { Box, Grid, Typography } from '@mui/material';
import { useContext } from 'react';

import { DragDropContext, Droppable, Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

import { LessonsAddSlideButton } from './LessonsAddSlideButton';

import { SlideListContext } from '../contexts/LessonsSlideContext';
import { LessonsSlideListDelete } from './LessonsSlideListDelete';
import { SlideModel } from 'models/lesson/LessonModel';
import useLessonSlide from '../hooks/useLessonSlide';
import { SlidePreviewContextProvider } from '../contexts/SlidePreviewContext';
import { SlidePreview } from '../../testroom/views/SlidePreview';
import { UserAuthContext } from 'contexts/UserAuthContext';

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined): object => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 1,
    paddingLeft: 0,
    margin: `0 0 0 0`,

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
    padding: grid,
    width: '100%',
});

export const LessonsSlideList = ({ lessonID, slideData, lessonVersionID }: { lessonID: string; slideData: SlideModel[]; lessonVersionID: string }) => {
    const { selectedSlideIndex, setSelectedSlideIndex, lessonData } = useContext(SlideListContext);
    const { userData } = useContext(UserAuthContext);
    const { reorderItem, ratio } = useLessonSlide();

    const onDragEnd = (result: any) => {
        reorderItem(result);
    };

    const isOwner = userData?.id === lessonData?.teacher?.user?.id;
    const canEdit = !lessonData?.latestLessonDraft?.published && isOwner;

    return (
        <Box
            sx={{
                display: 'flex',
                flexFlow: 'column',
                height: '100%',
            }}>
            <Box
                sx={{
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: 'auto',
                    position: 'relative',
                }}>
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        overflow: 'auto',
                    }}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId='droppable'>
                            {(provided, snapshot) => (
                                <Box {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                    {slideData &&
                                        slideData.map((item: SlideModel, index: number) => (
                                            <Draggable isDragDisabled={!canEdit} key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <Grid
                                                        container
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                                        <Grid item xs={2} textAlign='right' sx={{ pr: 1 }}>
                                                            <Typography variant='body2'>{index + 1}.</Typography>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Box
                                                                sx={{
                                                                    position: 'relative',
                                                                }}>
                                                                <Box
                                                                    sx={{
                                                                        boxShadow: 4,
                                                                        border: 1,
                                                                        borderColor: '#DCCDB5',
                                                                        background: snapshot.isDragging || selectedSlideIndex === index ? '#FFA425' : 'white',
                                                                        aspectRatio: `${ratio}`,
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        justifyItems: 'center',
                                                                    }}
                                                                    onClick={() => {
                                                                        setSelectedSlideIndex(index);
                                                                    }}>
                                                                    <SlidePreviewContextProvider>
                                                                        <SlidePreview data={slideData[index].data} hasBorderColor={false} borderRadius='0px' />
                                                                    </SlidePreviewContextProvider>
                                                                </Box>
                                                                {canEdit && <LessonsSlideListDelete lessonID={lessonID} slideID={item.id} />}
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </Box>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Box>
            </Box>
            <Box
                sx={{
                    flexGrow: 0,
                    flexShrink: 1,
                    flexBasis: '60px',
                }}>
                {canEdit && <LessonsAddSlideButton lessonID={lessonID} lessonVersionID={lessonVersionID} />}
            </Box>
        </Box>
    );
};
