import { Stack, Grid, Box, Divider, ToggleButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SlideComponent, SlideComponentHkcsQ3 } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { SelectImageBox } from './components/SelectImageBox';
import { OptionsType } from './components/PropsImagePicker';
import { SetStateAction, useEffect, useState } from 'react';
import { SelectWordsBox } from './components/SelectWordsBox';
import { DoSound } from './components/DoSound';
import FlipIcon from '@mui/icons-material/Flip';

export type SelectChangeEvent<T = string> = (Event & { target: { value: T; name: string } }) | React.ChangeEvent<HTMLInputElement>;

export const LessonsSlidePropsHkcsQ3 = ({
    item,
    index,
    setCanSave,
    handleCreate,
}: {
    item: SlideComponent;
    index: number;
    setCanSave?: React.Dispatch<SetStateAction<boolean>>;
    handleCreate?: (item: SlideComponent) => void;
}) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();

    let itemContent: SlideComponentHkcsQ3 = item.content as SlideComponentHkcsQ3;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    const [gridHeight, setGridHeight] = useState('40vh');

    useEffect(() => {
        function handleResize() {
            // Get 50% of the height of the parent element
            // Assuming the parent's height is set to 80vh

            const parentOffset = 550;
            const newHeight = `${window.innerHeight - parentOffset}px`;
            setGridHeight(newHeight);
        }

        // Set the height initially
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setCanSave?.((itemContent.image !== '' || itemContent.imageURL !== '') && itemContent.characterList.length > 0);
    }, [item]);

    return (
        <>
            <Stack sx={{ flex: 1, m: 1 }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    <Box sx={{ flex: '1 1 150px' }}>
                        <SelectImageBox
                            keepMargin={true}
                            imageURL={itemContent.imageURL ?? ''}
                            image={itemContent.image ?? ''}
                            flip={itemContent.flip}
                            onSelected={(imageURL: string) => {
                                itemContent.imageURL = imageURL;
                                updateUI(true);
                            }}
                            handleDelete={() => {
                                itemContent.imageURL = '';
                                itemContent.image = '';
                                updateUI(true);
                            }}
                            onClickHandler={(option: OptionsType) => {
                                itemContent.image = option?.value ?? '';
                                updateUI(true);
                            }}
                        />
                        <ToggleButton
                            value='1'
                            selected={itemContent.flip === -1}
                            onChange={() => {
                                if (itemContent.flip === -1) {
                                    itemContent.flip = 1;
                                } else {
                                    itemContent.flip = -1;
                                }
                                updateUI(true);
                            }}
                            aria-label='bold'
                            sx={{
                                width: '100%',
                                mt: 1,
                                borderRadius: '12px',
                                fontWeight: '700',
                                fontSize: '18px',
                                lineHeight: '150%',
                                color: '#FDFCFC',
                                backgroundColor: itemContent.flip === -1 ? '#efeae6' : '#F06E3C',
                                '&:hover': {
                                    backgroundColor: itemContent.flip === -1 ? '#e2dddb' : '#C84522',
                                },
                            }}>
                            <FlipIcon sx={{ mr: 2 }} /> {t('flip')}
                        </ToggleButton>
                    </Box>
                    <Box sx={{ flex: '1 1 150px' }}>
                        <SelectWordsBox
                            singleWord={false}
                            canSelectQuestion={true}
                            questionLabel={t('hkcs.q3.questionLabel')}
                            characterList={itemContent?.characterList}
                            phrase={itemContent.phrase}
                            phraseSound={itemContent.phraseSound}
                            phraseSoundGroup={itemContent.phraseSoundGroup}
                            onPhraseSoundGroupChanged={(phraseSoundGroup) => {
                                itemContent.phraseSoundGroup = phraseSoundGroup;
                                updateUI(true);
                            }}
                            selectedCharacterIndex={itemContent.character}
                            onCharacterPicked={(characterPhrase) => {
                                itemContent.characterList = characterPhrase?.character;
                                itemContent.character = 0;
                                itemContent.characterSound = {
                                    jyutping: characterPhrase?.character?.[itemContent.character]?.jyutping,
                                    pinyin: characterPhrase?.character?.[itemContent.character]?.pinyin,
                                };
                                itemContent.phrase = characterPhrase?.phrase;
                                itemContent.phraseSound = {
                                    jyutping: characterPhrase?.jyutping,
                                    pinyin: characterPhrase?.pinyin,
                                };
                                updateUI(true);
                            }}
                            onQuestionCharacterSelected={(index, character) => {
                                itemContent.character = index;

                                itemContent.characterSound = {
                                    jyutping: character.jyutping,
                                    pinyin: character.pinyin,
                                };

                                updateUI(true);
                            }}
                        />
                    </Box>
                </Box>
                <Divider sx={{ p: 2 }} />
                <Stack direction={'row'} spacing={1} sx={{ mt: 2 }}>
                    <DoSound
                        soundString={itemContent?.phraseSound?.jyutping?.[0]?.sound}
                        phrase={itemContent?.phrase}
                        groupIndex={itemContent?.phraseSoundGroup?.jyutping?.[0]?.startIndex || 0}
                        folder='jyutping'
                        sx={{
                            bgcolor: '#23B1D3',
                            color: '#FAFAFA',
                            '&:hover': {
                                bgcolor: '#23B1D3',
                                color: '#FAFAFA',
                                opacity: '0.7',
                            },
                            pointerEvents: 'auto',
                        }}>
                        {t('cantonese')}
                    </DoSound>

                    <DoSound
                        soundString={itemContent?.phraseSound?.pinyin?.[0]?.sound}
                        phrase={itemContent?.phrase}
                        groupIndex={itemContent?.phraseSoundGroup?.pinyin?.[0]?.startIndex || 0}
                        folder='pinyin'
                        sx={{
                            bgcolor: '#FFFFFF',
                            color: '#29C0E7',
                            '&:hover': {
                                bgcolor: '#FFFFFF',
                                color: '#29C0E7',
                                opacity: '0.7',
                            },
                            pointerEvents: 'auto',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: '#29C0E7',
                        }}>
                        {t('putonghua')}
                    </DoSound>
                </Stack>
            </Stack>
        </>
    );
};
