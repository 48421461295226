export namespace Constants {
    export const localStorageKeys = {
        language: process.env.REACT_APP_LOCAL_STORAGE_KEY_LANGUAGE!,
        theme: process.env.REACT_APP_LOCAL_STORAGE_KEY_THEME!,
        userData: process.env.REACT_APP_LOCAL_KEY_USER_DATA!,
        selectedSchool: process.env.REACT_APP_LOCAL_KEY_SELECTED_SCHOOL_DATA!,
        isMenuOpened: 'STORAGE_IS_MENU_OPENED',
        password_AES_SecretKey: process.env.REACT_APP_PASSWORD_SECRET_KEY,
    };
    export const main = {
        defaultLanguage: 'zh-HK',
    };
    export const lesson = {
        defaultLocale: 'zh-HK',
        fillInTheBlankQuestionLimit: 6,
    };
}
