import React, { useContext } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { Close, Check } from '@mui/icons-material';
import { PaymentPlanCard } from './PaymentPlanCard';
import { useTranslation } from 'react-i18next';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import HelpIcon from '@mui/icons-material/Help';
import { HoverHelp } from './components/HoverHelp';
import { UserPermissionContext } from '../../contexts/UserPermissionContext';
import { UserAuthContext } from '../../contexts/UserAuthContext';

export enum PaymentPlanButtonType {
    Contact = 'Contact',
    Subscribe = 'Subscribe',
}

export type PlanName =
    | 'IND_WORKSHEET_MONTHLY'
    | 'IND_WORKSHEET_ANNUAL'
    | 'IND_BASIC_MONTHLY'
    | 'IND_BASIC_ANNUAL'
    | 'IND_PRO_MONTHLY'
    | 'IND_PRO_ANNUAL'
    | 'SCH_BASIC_MONTHLY'
    | 'SCH_BASIC_ANNUAL'
    | 'SCH_PRO_MONTHLY'
    | 'SCH_PRO_ANNUAL'
    | 'TAILORED';

export type PlanInterval = 'monthly' | 'annual';

export interface SubscribeButtonProps {
    buttonType: PaymentPlanButtonType;
    internalPlanName?: PlanName;
    planInterval?: PlanInterval;
}

interface PaymentPlansProps {
    category: string;
    period: number;
    isComparisonView?: boolean;
    onSubscribeButtonClick: (props: SubscribeButtonProps) => void;
}

interface PlanDetail {
    item: string;
    inPlan: boolean | string | JSX.Element;
    details: string;
    icon?: string; // Optional property
}

interface Plan {
    name: string;
    internalName: string;
    annual: string;
    monthly: string;
    mostPopularLabel?: string;
    isTailored: boolean;
    chip?: string;
    description: JSX.Element;
    details: PlanDetail[];
}

interface PricingCategory {
    category: string;
    plans: Plan[];
}

export const PaymentPlans: React.FC<PaymentPlansProps> = ({ category, period, isComparisonView = false, onSubscribeButtonClick }) => {
    const { t } = useTranslation();

    const pricing: PricingCategory[] = [
        {
            category: 'individual',
            plans: [
                {
                    name: t('pricing.worksheet'),
                    internalName: 'IND_WORKSHEET',
                    annual: 'HK$20',
                    monthly: 'HK$25',
                    // annual: 'Beta 期免費',
                    // monthly: 'Beta 期免費',
                    isTailored: false,
                    chip: t('pricing.worksheet-chip'),
                    description: (
                        <div>
                            <h3>{t('pricing.suitable-title')}</h3>
                            <ul>
                                <li>{t('pricing.worksheet-suitable')}</li>
                            </ul>
                            <h3>{t('pricing.content-title')}</h3>
                            <ul>
                                {(
                                    t('pricing.worksheet-content', {
                                        returnObjects: true,
                                    }) as string[]
                                ).map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    ),
                    details: [
                        {
                            item: t('pricing.words'),
                            inPlan: false,
                            details: '香港教育局《香港小學學習字詞表》字詞 （分為第一學習階段及第二學習階段）',
                        },
                        {
                            item: t('pricing.description.pronunciation-cantonese'),
                            inPlan: false,
                            details: '真人廣東話字與詞發音',
                        },
                        {
                            item: t('pricing.description.pronunciation-putonghua'),
                            inPlan: false,
                            details: '真人普通話字與詞發音',
                        },

                        {
                            item: t('pricing.upload-images'),
                            inPlan: false,
                            details: '自己上傳圖表製作教材，運用於課堂及功課中',
                        },
                        {
                            item: t('pricing.worksheet-function'),
                            inPlan: true,
                            details: '完美整合Google Docs，輕鬆使用AI製作教材',
                            icon: '/icons/icon-ai.png',
                        },
                        {
                            item: t('pricing.max-num-templates'),
                            inPlan: false,
                            details: '用作於實時上堂的教材模板，形式有如 PowerPoint， 但更為互動及能記錄學生作答成績',
                        },
                        {
                            item: t('pricing.sharing'),
                            inPlan: false,
                            details: '與其他教材工作者分享成果，並發表自己的教學方法',
                        },
                        {
                            item: t('pricing.max-num-students'),
                            inPlan: false,
                            details: '依附於教師或家長帳戶的學生人數',
                        },
                        {
                            item: t('pricing.max-num-classes'),
                            inPlan: false,
                            details: '依附於教師或家長帳戶的班級人數',
                        },
                        {
                            item: t('pricing.description.ai-character-strokes'),
                            inPlan: false,
                            details: 'AI偵測及糾正學生於平板電腦上作答以《旅龜學習》編纂寫字題目時的筆順（筆順 100% 依照香港教育局的《學生小學學習字詞表》）',
                            icon: '/icons/icon-ai.png',
                        },
                        {
                            item: t('pricing.description.ai-reorder-sentence'),
                            inPlan: true,
                            details: '運用AI快速製作重組句子練習',
                            icon: '/icons/icon-ai.png',
                        },
                        {
                            item: t('pricing.description.ai-fill-in-the-blank'),
                            inPlan: true,
                            details: '運用AI快速製作供詞填充練習',
                            icon: '/icons/icon-ai.png',
                        },
                        {
                            item: t('pricing.description.ai-punctuation'),
                            inPlan: true,
                            details: '運用AI快速製作標點符號練習',
                            icon: '/icons/icon-ai.png',
                        },
                        {
                            item: t('pricing.description.ai-reading-comprehension'),
                            inPlan: true,
                            details: '運用AI快速製作閱讀理解練習。只需供應一篇文章，便能自動生成豐富的題目，省時間省精神。',
                            icon: '/icons/icon-ai.png',
                        },
                    ],
                },
                {
                    name: t('pricing.basic'),
                    internalName: 'IND_BASIC',
                    annual: 'HK$20',
                    monthly: 'HK$25',
                    // annual: 'Beta 期免費',
                    // monthly: 'Beta 期免費',
                    mostPopularLabel: t('pricing.popular-label-individual'),
                    isTailored: false,
                    chip: t('pricing.basic-chip'),
                    description: (
                        <div>
                            <h3>{t('pricing.suitable-title')}</h3>
                            <ul>
                                <li>{t('pricing.basic-suitable')}</li>
                            </ul>
                            <h3>{t('pricing.content-title')}</h3>
                            <ul>
                                {(
                                    t('pricing.basic-content', {
                                        returnObjects: true,
                                    }) as string[]
                                ).map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    ),
                    details: [
                        {
                            item: t('pricing.words'),
                            inPlan: '300',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.pronunciation-cantonese'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.pronunciation-putonghua'),
                            inPlan: true,
                            details: 'Something about Content',
                        },

                        {
                            item: t('pricing.upload-images'),
                            inPlan: false,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.worksheet-function'),
                            inPlan: false,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.max-num-templates'),
                            inPlan: '5',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.sharing'),
                            inPlan: false,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.max-num-students'),
                            inPlan: '3',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.max-num-classes'),
                            inPlan: '1',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-character-strokes'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-reorder-sentence'),
                            inPlan: false,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-fill-in-the-blank'),
                            inPlan: false,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-punctuation'),
                            inPlan: false,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-reading-comprehension'),
                            inPlan: false,
                            details: 'Something about Content',
                        },
                    ],
                },
                {
                    name: t('pricing.pro'),
                    internalName: 'IND_PRO',
                    annual: 'HK$95',
                    monthly: 'HK$119',
                    // annual: 'Beta 期免費',
                    // monthly: 'Beta 期免費',
                    isTailored: false,
                    chip: t('pricing.pro-chip'),
                    description: (
                        <div>
                            <h3>{t('pricing.suitable-title')}</h3>
                            <ul>
                                <li>{t('pricing.pro-suitable')}</li>
                            </ul>
                            <h3>{t('pricing.content-title')}</h3>
                            <ul>
                                {(
                                    t('pricing.pro-content', {
                                        returnObjects: true,
                                    }) as string[]
                                ).map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    ),
                    details: [
                        {
                            item: t('pricing.words'),
                            inPlan: '3,000+',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.pronunciation-cantonese'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.pronunciation-putonghua'),
                            inPlan: true,
                            details: 'Something about Content',
                        },

                        {
                            item: t('pricing.upload-images'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.worksheet-function'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.max-num-templates'),
                            inPlan: '10',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.sharing'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.max-num-students'),
                            inPlan: '5',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.max-num-classes'),
                            inPlan: '1',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-character-strokes'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-reorder-sentence'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-fill-in-the-blank'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-punctuation'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-reading-comprehension'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                    ],
                },
            ],
        },
        {
            category: 'school',
            plans: [
                {
                    name: t('pricing.basic'),
                    internalName: 'SCH_BASIC',
                    annual: 'HK$79',
                    monthly: 'HK$99',
                    // annual: 'Beta 期免費',
                    // monthly: 'Beta 期免費',
                    isTailored: false,
                    chip: t('pricing.basic-chip-school'),
                    description: (
                        <div>
                            <h3>{t('pricing.suitable-title')}</h3>
                            <ul>
                                <li>{t('pricing.basic-suitable-school')}</li>
                            </ul>
                            <h3>{t('pricing.content-title')}</h3>
                            <ul>
                                {(
                                    t('pricing.basic-content-school', {
                                        returnObjects: true,
                                    }) as string[]
                                ).map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                        // <>
                        //   <Typography>300 {t('pricing.description.words')}</Typography>
                        //   <Typography>10 {t('pricing.description.templates')}</Typography>
                        //   <Typography>20 {t('pricing.description.students')}</Typography>
                        //   <Typography>1 {t('pricing.description.class')}</Typography>
                        // </>
                    ),
                    details: [
                        {
                            item: t('pricing.words'),
                            inPlan: '300',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.pronunciation-cantonese'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.pronunciation-putonghua'),
                            inPlan: true,
                            details: 'Something about Content',
                        },

                        {
                            item: t('pricing.upload-images'),
                            inPlan: false,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.worksheet-function'),
                            inPlan: false,
                            details: 'Something about Content',
                            icon: '/icons/icon-ai.png',
                        },
                        {
                            item: t('pricing.max-num-templates'),
                            inPlan: '10',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.sharing'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.max-num-students'),
                            inPlan: t('pricing.up-to-20-students') as string,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.max-num-classes'),
                            inPlan: '1',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-character-strokes'),
                            inPlan: true,
                            details: 'Something about Content',
                            icon: '/icons/icon-ai.png',
                        },
                        {
                            item: t('pricing.description.ai-reorder-sentence'),
                            inPlan: false,
                            details: 'Something about Content',
                            icon: '/icons/icon-ai.png',
                        },

                        {
                            item: t('pricing.description.ai-fill-in-the-blank'),
                            inPlan: false,
                            details: 'Something about Content',
                            icon: '/icons/icon-ai.png',
                        },
                        {
                            item: t('pricing.description.ai-punctuation'),
                            inPlan: false,
                            details: 'Something about Content',
                            icon: '/icons/icon-ai.png',
                        },
                        {
                            item: t('pricing.description.ai-reading-comprehension'),
                            inPlan: false,
                            details: 'Something about Content',
                            icon: '/icons/icon-ai.png',
                        },
                    ],
                },
                {
                    name: t('pricing.pro'),
                    internalName: 'SCH_PRO',
                    annual: 'HK$399',
                    monthly: 'HK$499',
                    // annual: 'Beta 期免費',
                    // monthly: 'Beta 期免費',
                    isTailored: false,
                    mostPopularLabel: t('pricing.popular-label-school'),
                    chip: t('pricing.pro-chip-school'),
                    description: (
                        <div>
                            <h3>{t('pricing.suitable-title')}</h3>
                            <ul>
                                <li>{t('pricing.pro-suitable-school')}</li>
                            </ul>
                            <h3>{t('pricing.content-title')}</h3>
                            <ul>
                                {(
                                    t('pricing.pro-content-school', {
                                        returnObjects: true,
                                    }) as string[]
                                ).map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                        // <>
                        //   <Typography variant='h6'>{t('pricing.turtle-learn')}</Typography>
                        //   <Typography>3000+ {t('pricing.description.words')}</Typography>
                        //   <Typography>{t('pricing.upload-images')}</Typography>
                        //   <Typography>
                        //     {t('pricing.description.worksheet-function')}
                        //   </Typography>{' '}
                        //   <Typography>
                        //     {t('pricing.description.unlimited')}{' '}
                        //     {t('pricing.description.templates')}
                        //   </Typography>
                        //   <Typography>{t('pricing.description.share')}</Typography>
                        //   <Typography>20 {t('pricing.description.students')}</Typography>
                        //   <Typography>4 {t('pricing.description.classes')}</Typography>
                        // </>
                    ),
                    details: [
                        {
                            item: t('pricing.words'),
                            inPlan: '3,000+',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.pronunciation-cantonese'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.pronunciation-putonghua'),
                            inPlan: true,
                            details: 'Something about Content',
                        },

                        {
                            item: t('pricing.upload-images'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.worksheet-function'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.max-num-templates'),
                            inPlan: t('pricing.unlimited') as string,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.sharing'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.max-num-students'),
                            inPlan: t('pricing.up-to-40-students') as string,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.max-num-classes'),
                            inPlan: '4',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-character-strokes'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-reorder-sentence'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-fill-in-the-blank'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-punctuation'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-reading-comprehension'),
                            inPlan: true,
                            details: 'Something about Content',
                        },
                    ],
                },
                {
                    name: t('pricing.tailored'),
                    internalName: 'TAILORED',
                    annual: t('pricing.custom-pricing'),
                    monthly: t('pricing.custom-pricing'),
                    isTailored: true,
                    description: (
                        <>
                            <Typography>
                                <br />
                                {t('pricing.description.custom-description')}
                            </Typography>
                        </>
                    ),
                    details: [
                        {
                            item: t('pricing.words'),
                            inPlan: (
                                <>
                                    <br />
                                    {t('pricing.description.custom-description')}
                                </>
                            ),
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.pronunciation-cantonese'),
                            inPlan: '',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.pronunciation-putonghua'),
                            inPlan: '',
                            details: 'Something about Content',
                        },

                        {
                            item: t('pricing.upload-images'),
                            inPlan: '',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.worksheet-function'),
                            inPlan: '',
                            details: 'Something about Content',
                            icon: '/icons/icon-ai.png',
                        },
                        {
                            item: t('pricing.max-num-templates'),
                            inPlan: '',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.sharing'),
                            inPlan: '',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.max-num-students'),
                            inPlan: '',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.max-num-classes'),
                            inPlan: '',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-character-strokes'),
                            inPlan: '',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-reorder-sentence'),
                            inPlan: '',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-fill-in-the-blank'),
                            inPlan: '',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-punctuation'),
                            inPlan: '',
                            details: 'Something about Content',
                        },
                        {
                            item: t('pricing.description.ai-reading-comprehension'),
                            inPlan: '',
                            details: 'Something about Content',
                        },
                    ],
                },
            ],
        },
    ];

    const plans = pricing.find((item) => item.category === category)?.plans || [];
    const selectedPeriod = period === 0 ? 'annual' : 'monthly';

    const { userPermissions } = useContext(UserPermissionContext);
    const auth = useContext(UserAuthContext);

    const isCurrentPlan = (selectedPlan: string, selectedPeriod: string) => {
        const userProduct = auth.selectedSchool?.subscriptionHistories?.[0]?.product;
        const planIntervalSuffix = '_' + selectedPeriod.toUpperCase();
        const selectedPlan_internalName = selectedPlan + planIntervalSuffix;
        // console.log('selectedPlan_internalName', selectedPlan_internalName);
        // console.log('userProduct.internalName', userProduct?.internalName);
        if (!userProduct) {
            return false;
        }
        return userProduct.internalName == selectedPlan_internalName || userProduct.internalName?.startsWith('TAILORED');
    };
    return (
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between' width='100%'>
            {/* TYPE I: Comparison view features list ONLY */}

            {isComparisonView ? (
                <PaymentPlanCard
                    minWidth={380}
                    showPopularPlan={false}
                    planTitle={''}
                    planPrice={''}
                    popularPlanLabel={''}
                    showPerMonth={false}
                    showChip={false}
                    showSubscribeButton={false}
                    planDetails={
                        <Stack
                            direction='column'
                            spacing={2}
                            display='flex'
                            justifyContent='flex-start'
                            // alignItems='center'
                        >
                            {plans[0].details.map((detail, index) => (
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        height: '25px',
                                    }}
                                    key={index}>
                                    <span>
                                        {detail.item}
                                        {(detail as { icon?: string }).icon ? (
                                            <Box
                                                component='img'
                                                src={
                                                    (
                                                        detail as {
                                                            icon?: string;
                                                        }
                                                    ).icon
                                                        ? (
                                                              detail as {
                                                                  icon?: string;
                                                              }
                                                          ).icon
                                                        : ''
                                                }
                                                alt='AI power'
                                                sx={{
                                                    position: 'relative',
                                                    top: -10,
                                                    right: 0,
                                                    width: 15,
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </span>
                                    <HoverHelp content={detail.details} />
                                    {/* <HelpIcon style={{ color: 'grey', width: 20 }} /> */}
                                </Typography>
                            ))}
                        </Stack>
                    }
                />
            ) : (
                <></>
            )}

            {plans.map((plan) => (
                <PaymentPlanCard
                    key={plan.name}
                    showPopularPlan={plan.mostPopularLabel ? true : false}
                    planTitle={plan.name}
                    planPrice={plan[selectedPeriod]}
                    highlightPlanPrice={selectedPeriod == 'annual' && !plan.isTailored ? true : false}
                    popularPlanLabel={plan.mostPopularLabel ? plan.mostPopularLabel : ''}
                    showPerMonth={plan.isTailored ? false : true}
                    isTailored={plan.isTailored}
                    chipTitle={plan.chip ? plan.chip : ''}
                    subscribeButtonTitle={isCurrentPlan(plan.internalName, selectedPeriod) ? t('pricing.current-plan') : plan.isTailored ? t('pricing.contact') : t('pricing.subscribe')}
                    disabled={isCurrentPlan(plan.internalName, selectedPeriod)}
                    subscribeButtonType={plan.isTailored ? 'subscribe' : 'contact'}
                    showChip
                    showSubscribeButton
                    showBottomControls={isComparisonView}
                    onSubscribeButtonClick={() => {
                        // Two scenarios:
                        // Scenario A: PaymentPanel is embedded on turtlelearn.com
                        // A1) Contact Us will lead to '/school-order' page
                        // A2) Subscribe will lead to turtleteach's onboarding flow

                        // Scenario B: PaymentPanel is in Turtle Teach's Profile Page
                        // B1) Contact Us will lead to turtlelearn.com's /school-order page
                        // B2) Subscribe will lead to subscription upgrade / downgrade flow
                        // const targetPath = '/school-order';
                        const buttonProps: SubscribeButtonProps = plan.isTailored
                            ? {
                                  buttonType: PaymentPlanButtonType.Contact,
                                  internalPlanName: (plan.internalName + '_' + (selectedPeriod as 'monthly' | 'annual').toUpperCase()) as
                                      | 'IND_WORKSHEET_MONTHLY'
                                      | 'IND_WORKSHEET_ANNUAL'
                                      | 'IND_BASIC_MONTHLY'
                                      | 'IND_BASIC_ANNUAL'
                                      | 'IND_PRO_MONTHLY'
                                      | 'IND_PRO_ANNUAL'
                                      | 'SCH_BASIC_MONTHLY'
                                      | 'SCH_BASIC_ANNUAL'
                                      | 'SCH_PRO_MONTHLY'
                                      | 'SCH_PRO_ANNUAL'
                                      | 'TAILORED', // Cast as needed
                              }
                            : {
                                  buttonType: PaymentPlanButtonType.Subscribe,
                                  internalPlanName: (plan.internalName + '_' + (selectedPeriod as 'monthly' | 'annual').toUpperCase()) as
                                      | 'IND_WORKSHEET_MONTHLY'
                                      | 'IND_WORKSHEET_ANNUAL'
                                      | 'IND_BASIC_MONTHLY'
                                      | 'IND_BASIC_ANNUAL'
                                      | 'IND_PRO_MONTHLY'
                                      | 'IND_PRO_ANNUAL'
                                      | 'SCH_BASIC_MONTHLY'
                                      | 'SCH_BASIC_ANNUAL'
                                      | 'SCH_PRO_MONTHLY'
                                      | 'SCH_PRO_ANNUAL'
                                      | 'TAILORED', // Cast as needed
                                  planInterval: selectedPeriod as 'monthly' | 'annual', // Ensure this is either 'monthly' or 'annual'
                              };

                        onSubscribeButtonClick(buttonProps);
                    }}
                    planDetails={
                        /* TYPE II: Comparison Details View */
                        isComparisonView ? (
                            <Stack direction='column' spacing={2} display='flex' justifyContent='center' alignItems='center'>
                                {plan.details.map((detail, index) => (
                                    <Typography sx={{ height: '25px' }} key={index}>
                                        {/* {detail.item}{' '} */}
                                        {typeof detail.inPlan === 'boolean' ? detail.inPlan ? <Check style={{ color: 'green' }} /> : <Close style={{ color: 'red' }} /> : detail.inPlan}
                                    </Typography>
                                ))}
                            </Stack>
                        ) : (
                            /* TYPE III: Pricing Card Descriptions View */
                            <Stack
                                direction='column'
                                spacing={2}
                                display='flex'
                                justifyContent='center'
                                // alignItems='center'
                            >
                                <Typography sx={{ height: '25px' }}>{plan.description}</Typography>
                            </Stack>
                        )
                    }
                />
            ))}
        </Stack>
    );
};
