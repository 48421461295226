import { useState, useEffect, CSSProperties } from 'react';
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import { useMutation } from '@apollo/client';
import { AUTHENTICATE_WITH_GOOGLE } from 'pages/onBoarding/queries';

// define your response type
interface AuthenticateWithGoogleResponse {
    authenticateWithGoogle: {
        __typename: string;
        [key: string]: any;
    };
}

interface AuthenticateWithGoogleMutationVariables {
    token: string;
}

export const GoogleSigninButton = (props: { buttonTitle?: string; onSuccess: any }) => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    if (!clientId) {
        throw new Error('Google Client Id environment variable is not defined');
    }

    const [authToken, setAuthToken] = useState<string | null>(null);
    const [hover, setHover] = useState(false);
    const [gButtonClick, setGButtonClick] = useState(false);

    const baseStyle: CSSProperties = {
        background: 'white',
        color: 'black',
        border: 'silver 1px solid',
        borderRadius: '12px',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontWeight: '500', // Use 500 for medium weight
        fontSize: '16px',
        width: '100%',
        lineHeight: '18px',
        boxShadow: '0px 4px 4px rgba(37, 37, 37, 0.35)',
        position: 'relative', // This allows the overlay to be positioned correctly
    };

    const hoverStyle: CSSProperties = {
        ...baseStyle,
        cursor: 'pointer',
        boxShadow: '0px 0px 0px rgba(37, 37, 37, 0.35)',
    };

    const overlayStyle: CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.2)', // This is the overlay color
        opacity: hover ? 1 : 0, // This changes the opacity based on hover state
        transition: 'opacity 0.2s ease', // This adds a transition effect
        borderRadius: '9px', // Adjust this value
    };

    const [authenticateWithGoogle, { data, loading: googleLoading, error: googleError }] =
        useMutation<AuthenticateWithGoogleResponse, AuthenticateWithGoogleMutationVariables>(AUTHENTICATE_WITH_GOOGLE);

    const login = useGoogleLogin({
        scope: 'https://www.googleapis.com/auth/documents profile email',
        onSuccess: (codeResponse) => {
            // console.log(codeResponse);
            setAuthToken(codeResponse.code); // assuming access_token is in the codeResponse
        },
        flow: 'auth-code',
    });

    useEffect(() => {
        if (authToken) {
            // console.log('Calling authenticateWithGoogle...', authToken);
            authenticateWithGoogle({
                variables: { token: authToken.toString() },
            })
                .then((result: any) => {
                    // console.log('authenticateWithGoogle success:', result);
                    // console.log(props);
                    // handle result
                    props.onSuccess(result);
                })
                .catch((error: any) => {
                    console.log('authenticateWithGoogle error:', error);

                    // handle error
                    props.onSuccess(error);
                });
        }
    }, [authToken, authenticateWithGoogle]);

    return (
        <div>
            <button
                style={{ border: 'none', background: 'transparent' }}
                onMouseEnter={() => {
                    setHover(true);
                    setGButtonClick(false);
                }}
                onMouseLeave={() => {
                    setHover(false);
                    setGButtonClick(false);
                }}
                onClick={() => {
                    setHover(false);
                    setGButtonClick(true);
                    login();
                }}>
                <img
                    src={process.env.PUBLIC_URL + (gButtonClick ? '/asset/images/google-btn-pressed.png' : hover ? '/asset/images/google-btn-focused.png' : '/asset/images/google-btn-normal.png')}
                    alt='Google Button'
                    height='60px'
                />
            </button>
            {/* <button
        style={hover ? hoverStyle : baseStyle}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => login()}>
        <img
          src='https://developers.google.com/identity/images/g-logo.png'
          alt='Google logo'
          height={'20px'}
        />
        <div style={overlayStyle} /> 
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span>{props.buttonTitle || 'Sign in with Google'}</span>
      </button> */}
            <br></br>
        </div>
    );
};

// export default GoogleSigninButton;
