import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { convertLength } from '@mui/material/styles/cssUtils';

import { SlideComponentFillInTheBlank, SlideComponentFillInTheBlankQuestionModel, SlideComponentMultipleChoiceQuestionModel } from 'models/lesson/LessonModel';

const { v4: uuidv4 } = require('uuid');

const marginLeft = 100;
const marginTop = 200;

type SentenceModel = {
    answerText: string;
    questionText: string;
};

type MultpleChoiceQuestionModel = {
    questionText: string;
    choices: string[];
    answerIndex: number;
};

type GenerateTextQueryModel = {
    generateTextFillInTheBlank: SentenceModel[];
};

const GENERATE_TEXT_QUERY = gql`
    query GenText($questionTopic: String!, $questionLength: String!) {
        generateTextFillInTheBlank(questionTopic: $questionTopic, questionLength: $questionLength) {
            answerText
            questionText
        }
    }
`;

const GENERATE_TEXT_GENERIC_QUERY = gql`
    query GenTextGeneric($questionPrompt: String!) {
        generateTextGeneric(questionPrompt: $questionPrompt) {
            replyText
        }
    }
`;

export const useLessonsSlidePropsFillInTheBlankHook = (itemContent: SlideComponentFillInTheBlank) => {
    const [genText, { loading: genTextLoading }] = useLazyQuery<GenerateTextQueryModel>(GENERATE_TEXT_QUERY, {
        fetchPolicy: 'no-cache',
    });

    const [genTextGeneric, { loading }] = useLazyQuery(GENERATE_TEXT_GENERIC_QUERY, {
        fetchPolicy: 'no-cache',
    });

    const getTextFromServer = async (questionLength: string, questionTopic: string, isRegenerate: boolean) => {
        if (!isRegenerate && itemContent.questions.length > 0) {
            return;
        }
        try {
            const result = await genText({
                variables: { questionLength, questionTopic },
            });
            UpdateQuestionArrayInfo(result.data?.generateTextFillInTheBlank ?? []);
        } catch (err) {
            console.log('gen text error:', err);
        }
    };

    const parseResultsFillInTheBlank = (results: string, questionCountLimit?: number) => {
        // const regex = /(.+):(.+)/g;
        const regex = /\d+\.\s([^|]+)\s\|\s(.+)/g;

        let match;
        const sentences: any = [];
        let validQuestionCount = 0;
        while ((match = regex.exec(results)) !== null) {
            // console.log('match:', match);
            // if (match[1].indexOf('答案') !== -1) {
            //   continue;
            // }
            let answerText = match[1].trim();
            let questionText = match[2].trim();
            const containsAnswerText = questionText.includes(answerText);

            if (!containsAnswerText) {
                // console.log(
                //   'questionText: ',
                //   questionText,
                //   ' does not contain answerText: ',
                //   answerText,
                //   ' questionCountLimit:',
                //   questionCountLimit,
                //   'validQuestionCount: ',
                //   validQuestionCount,
                // );

                if (questionCountLimit) {
                    if (questionCountLimit > validQuestionCount) {
                        continue;
                    }
                }
            } else {
                // console.log(
                //   ' questionCountLimit:',
                //   questionCountLimit,
                //   'validQuestionCount: ',
                //   validQuestionCount,
                // );
            }
            validQuestionCount = validQuestionCount + 1;

            if (questionCountLimit && questionCountLimit < validQuestionCount) {
                break;
            }

            questionText = questionText.replace(new RegExp(`(${answerText})`, 'g'), '____');

            // console.log('answerText: ', answerText);
            // console.log('questionText: ', questionText);

            sentences.push({
                answerText: answerText,
                questionText: questionText,
            });
            // console.log(`Word: ${match[1]}, Sentence: ${match[2]}`);
        }

        return sentences; // Return the parsed sentences
    };

    const parseResultsMultipleChoice = (results: string) => {
        const blocks = results.split(/\n\s*\n/); // Split the text into blocks using blank lines as separators

        const parsedBlocks = blocks.map((block: string) => {
            const questionMatch = block.match(/^.+/);
            const questionText = questionMatch ? questionMatch[0].trim() : '';

            const choicesRegex = /[A-D]\)\s(.*)(?=(?:\n(?!答案：)[A-D])|\n答案：)/g;
            const choicesMatch = block.match(choicesRegex);
            const choices = choicesMatch ? choicesMatch.map((choice) => choice.replace(/[A-D]\)\s/, '').trim()) : [];

            const answerRegex = /答案：([A-D])\)/;
            const answerMatch = block.match(answerRegex);
            const answerIndex = answerMatch ? answerMatch[1].charCodeAt(0) - 'A'.charCodeAt(0) : -1;

            return { questionText, choices, answerIndex };
        });

        // console.log(parsedBlocks);
        return parsedBlocks;
    };

    const getTextFromServerGeneric = async (questionType: string, questionPrompt: string, isRegenerate: boolean, questionCountLimit?: number) => {
        if (!isRegenerate && itemContent.questions.length > 0) {
            return;
        }
        try {
            const result = await genTextGeneric({
                variables: { questionPrompt },
            });

            // console.log('Kendall AI result: ', result);
            // console.log(result.data.generateTextGeneric.replyText);
            let sentences = undefined;
            if (questionType == 'fillInTheBlank') {
                if (questionCountLimit) {
                    sentences = parseResultsFillInTheBlank(result.data.generateTextGeneric.replyText, questionCountLimit);
                } else {
                    sentences = parseResultsFillInTheBlank(result.data.generateTextGeneric.replyText);
                }

                // console.log('Finished Parsing');
                //"1. 智能筆盒也被稱為__筆盒。\n聰明\n2. 我的聰明筆盒外觀像__，卻只有它的十分之一大小。\n黑色鞋盒\n3. 聰明筆盒的功能不止__和關蓋，還能為你削鉛筆，用螢幕操作計算機及看串流影片等。\n開蓋和關蓋"

                UpdateQuestionArrayInfoFillInTheBlank(sentences ?? []);
            } else if (questionType == 'multipleChoice') {
                let blocks = parseResultsMultipleChoice(result.data.generateTextGeneric.replyText);
                // 1. 這個筆盒的尺寸是多少？
                // A) 十厘米長、五厘米闊、三厘米高
                // B) 十厘米長、十厘米闊、五厘米高
                // C) 二十厘米長、十厘米闊、五厘米高
                // D) 二十厘米長、五厘米闊、十厘米高
                // 答案：C) 二十厘米長、十厘米闊、五厘米高
                // UpdateQuestionArrayInfoMultipleChoice(blocks ?? []);
            }
        } catch (err) {
            console.log('gen text error:', err);
        }
    };

    // const UpdateQuestionArrayInfoMultipleChoice = (
    //   data: MultpleChoiceQuestionModel[],
    // ) => {
    //   let newQuestion: SlideComponentMultipleChoiceQuestionModel[] = [];
    //   let i = 0;
    //   for (const question of data) {
    //     newQuestion.push({
    //       id: uuidv4(),
    //       choices: question.choices,
    //       answerIndex: question.answerIndex,
    //       questionText: question.questionText,
    //       pos: {
    //         x: marginLeft + 400 * (i % 2),
    //         y: marginTop + 100 * Math.floor(i / 2),
    //       },
    //     });
    //     i++;
    //   }
    //   itemContent.questions = newQuestion;
    //   updateUI();
    // };

    const UpdateQuestionArrayInfoFillInTheBlank = (data: SentenceModel[]) => {
        let newQuestion: SlideComponentFillInTheBlankQuestionModel[] = [];
        let i = 0;
        for (const question of data) {
            newQuestion.push({
                id: uuidv4(),
                answerText: question.answerText,
                questionText: question.questionText,
                sort: 0,
            });
            i++;
        }
        itemContent.questions = newQuestion;
    };

    const UpdateQuestionArrayInfo = (data: SentenceModel[]) => {
        let newQuestion: SlideComponentFillInTheBlankQuestionModel[] = [];
        let i = 0;
        for (const question of data) {
            newQuestion.push({
                id: uuidv4(),
                answerText: question.answerText,
                questionText: question.questionText,
                sort: 0,
            });
            i++;
        }
        itemContent.questions = newQuestion;
    };
    const deleteQuestionArrayInfo = (idx: number) => {
        const indexToDelete = idx; // delete the item at index 1
        const newQuestion = itemContent.questions.filter((item, index) => index !== indexToDelete);
        itemContent.questions = newQuestion;
    };

    const addQuestion = () => {
        let currentIdx = itemContent.questions.length;
        itemContent.questions.push({
            id: uuidv4(),
            answerText: '',
            questionText: '',
            sort: 0,
        });
    };
    const questionFieldChange = (idx: number, textContent: string) => {
        itemContent.questions[idx].questionText = textContent;
    };
    const answerFieldChange = (idx: number, textContent: string) => {
        itemContent.questions[idx].answerText = textContent;
    };
    return {
        getTextFromServer,
        getTextFromServerGeneric,
        deleteQuestionArrayInfo,
        questionFieldChange,
        answerFieldChange,
        loading,
        hasGenerated: itemContent.questions.length > 0,
        addQuestion,
    };
};
