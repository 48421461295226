import { Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LessonModel } from 'models/lesson/LessonModel';
import { useContext, useRef } from 'react';
import { SlideListContext } from '../../lessons/contexts/LessonsSlideContext';
import { SlidePreview } from '../views/SlidePreview';
import { SlidePreviewContextProvider } from '../../lessons/contexts/SlidePreviewContext';
import { GridCaption, ColoredPCO, SmallGridCaption } from '../TestroomTemplate';
import { ClassroomContext } from '../contexts/ClassroomContextProvider';
import { requestUpdateRoomSettings, RoomSettings } from '../hooks/classroomHooks/requestUpdateRoomSettings';

export const TestroomSlide = ({ data }: { data?: LessonModel }) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const { setSelectedSlideIndex, selectedSlideIndex } = useContext(SlideListContext);
    const { socket, testroomData } = useContext(ClassroomContext);

    const buttonLeft = () => {
        setSelectedSlideIndex((prev) => {
            if (prev > 0) {
                const previousPage = prev - 1;

                if (process.env.REACT_APP_NEW_CLASSROOM_FLOW_ENABLED == 'true') {
                    requestUpdateRoomSettings(socket, testroomData?.id ?? '', { page: previousPage }, true, (response: RoomSettings) => {});
                }

                return previousPage;
            } else {
                return prev;
            }
        });
    };
    const buttonRight = () => {
        setSelectedSlideIndex((prev) => {
            if (prev < (data?.latestLessonDraft?.slides.length ?? 0) - 1) {
                const nextPage = prev + 1;

                if (process.env.REACT_APP_NEW_CLASSROOM_FLOW_ENABLED == 'true') {
                    requestUpdateRoomSettings(socket, testroomData?.id ?? '', { page: nextPage }, true, (response: RoomSettings) => {});
                }

                return nextPage;
            } else {
                return prev;
            }
        });
    };

    return (
        <Stack ref={containerRef} justifyContent='center' alignItems='center' sx={{ width: '100%', height: '100%' }}>
            <GridCaption sx={{ marginBottom: '9px' }}>{t('live-class-slide')}</GridCaption>
            <SlidePreviewContextProvider>
                <SlidePreview data={data?.latestLessonDraft?.slides?.[selectedSlideIndex]?.data} hasBorderColor={true} borderRadius='32px' />
            </SlidePreviewContextProvider>
            <Stack
                sx={{
                    flexDirection: 'row',
                    gap: '10px',
                    marginTop: '8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Button onClick={() => buttonLeft()}>
                    <ColoredPCO
                        sx={{
                            transform: 'rotate(0.5turn)',
                        }}
                    />
                </Button>
                <SmallGridCaption>
                    Slide {selectedSlideIndex + 1}/{data?.latestLessonDraft?.slides?.length}
                </SmallGridCaption>
                <Button onClick={() => buttonRight()}>
                    <ColoredPCO />
                </Button>
            </Stack>
        </Stack>
    );
};
