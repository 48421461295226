import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, Typography, InputAdornment, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useOutletContext } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import { UserAuthContext, UserData } from 'contexts/UserAuthContext';
import { BootstrapStyleInput } from 'components/BootstrapStyleInput';
import { SIGN_IN, AUTHENTICATE_WITH_GOOGLE } from 'pages/onBoarding/queries';
import { OnBoardingPageContext } from 'pages/onBoarding/models';
import { SignInRules } from 'pages/onBoarding/utils';
import { RemoveRedEye, RemoveRedEyeOutlined } from '@mui/icons-material';
import { ForgotPasswordPath } from 'routes/utils';
import Cookies from 'universal-cookie';
import { GoogleSigninButton } from 'components/GoogleSigninButton';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { UserPrefContext } from 'contexts/UserPrefContext';
import TermsDialog from '../SignUp/TermsDialog';
import { TermsConditionsContent } from '../SignUp/TermsConditionsContent';
import ReactDOM from 'react-dom';

// define your response type
interface AuthenticateWithGoogleResponse {
    authenticateWithGoogle: {
        __typename: string;
        [key: string]: any;
    };
}
// type UserData = {
//   id: string;
//   email: string;
//   name: string;
//   role: string;
//   googleEmail: string;
//   googleProfilePicture: string;
// };

export type ResultType = {
    data: {
        [key: string]: {
            __typename: string;
            message?: string;
            item: UserData;
            sessionToken: string;
        };
    };
};

export const SignInPage = () => {
    const { isMenuOpened, setIsMenuOpened } = useContext(UserPrefContext);

    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    if (!clientId) {
        throw new Error('Google Client Id environment variable is not defined');
    }

    const { t } = useTranslation();
    const { setUserData, setRememberMe } = useContext(UserAuthContext);
    const { setErrorMessage, setIsLoading } = useOutletContext<OnBoardingPageContext>();

    const [doLogin, { loading: loginLoading, error: loginError }] = useMutation(SIGN_IN);

    const [showPassword, setShowPassword] = useState(false); // Add the showPassword state

    const [authenticateWithGoogle, { data, loading: googleLoading, error: googleError }] = useMutation(AUTHENTICATE_WITH_GOOGLE);
    const loading = loginLoading || googleLoading;
    const error = loginError || googleError;

    const cookies = new Cookies();

    const [showTermsDialog, setShowTermsDialog] = useState(false);

    const url = new URL(window.location.href);
    const params = url.searchParams;
    const fallbackParam = params.get('fallback');
    const fallbackPath = encodeURIComponent(fallbackParam || '');

    const handleResponse = async (result: ResultType | undefined, rememberMe: boolean, authenticateUserWithPasswordOrGoogle: string) => {
        return new Promise<void>((resolve, reject) => {
            if (
                result?.data?.[authenticateUserWithPasswordOrGoogle]?.__typename === 'UserAuthenticationWithPasswordFailure' ||
                result?.data?.[authenticateUserWithPasswordOrGoogle]?.__typename === 'AuthenticateWithGoogleFailure'
            ) {
                setErrorMessage(result.data[authenticateUserWithPasswordOrGoogle].message || 'An error occurred');
                return;
            }

            if (!result?.data?.[authenticateUserWithPasswordOrGoogle]?.item) {
                throw new Error('User data is missing');
            }

            if (result?.data[authenticateUserWithPasswordOrGoogle].item && result?.data[authenticateUserWithPasswordOrGoogle].item?.role !== 'teacher') {
                throw new Error('This user is not a teacher');
            }

            const doLogin = () => {
                const userData: UserData = {
                    id: result.data[authenticateUserWithPasswordOrGoogle].item.id,
                    email: result.data[authenticateUserWithPasswordOrGoogle].item.email,
                    name: result.data[authenticateUserWithPasswordOrGoogle].item.name,
                    role: result.data[authenticateUserWithPasswordOrGoogle].item.role,
                    googleId: result.data[authenticateUserWithPasswordOrGoogle].item.googleId,
                    googleEmail: result.data[authenticateUserWithPasswordOrGoogle].item.googleEmail,
                    googleProfilePicture: result.data[authenticateUserWithPasswordOrGoogle].item.googleProfilePicture,
                };

                if (rememberMe && result?.data?.[authenticateUserWithPasswordOrGoogle]?.item) {
                    const inThirtyDays = new Date();
                    inThirtyDays.setDate(inThirtyDays.getDate() + 30);

                    cookies.set('userData', JSON.stringify(userData), {
                        expires: inThirtyDays,
                    });
                }

                localStorage.setItem('token', result?.data?.[authenticateUserWithPasswordOrGoogle].sessionToken);

                setUserData(userData);
                setIsMenuOpened(true); // Make side menu expanded state by default after login
            };

            // console.log(
            //   result?.data?.[authenticateUserWithPasswordOrGoogle]?.__typename,
            // );
            if (result?.data?.[authenticateUserWithPasswordOrGoogle]?.__typename === 'SignUpWithGoogleSuccess') {
                setShowTermsDialog(true);
                const onClose = () => {
                    setShowTermsDialog(false);
                    doLogin();
                    resolve(); // Resolve the promise when the dialog is closed
                };
                const dialogRoot = document.getElementById('dialog-root');
                ReactDOM.render(
                    <TermsDialog label={''} buttonTitle={''} dialogTitle={t('terms-conditions-card-title')} content={<TermsConditionsContent />} onClose={onClose} isOpen={true} />,
                    dialogRoot,
                );
            } else {
                doLogin();
            }
        });
    };

    type FormData = { email: string; password: string; rememberMe: boolean };

    // For handleSubmit
    const handleSubmit = async (data: FormData) => {
        try {
            const result = await doLogin({
                variables: {
                    email: data.email.toLowerCase(),
                    password: data.password,
                },
                fetchPolicy: 'no-cache',
            });
            if (result && 'data' in result) {
                await handleResponse(result as ResultType, data.rememberMe, 'authenticateUserWithPassword');
            } else {
                throw new Error('An error occurred during login');
            }
        } catch (error) {
            if (error instanceof Error) {
                // type guard
                setErrorMessage(error.message);
            } else {
                // Handle the case when error is not an Error instance
                setErrorMessage('An error occurred.');
            }
        }
    };

    // For handleSubmitGoogle
    const handleSubmitGoogle = async (result: ResultType) => {
        try {
            await handleResponse(result, false, 'authenticateWithGoogle');
        } catch (error) {
            if (error instanceof Error) {
                // type guard
                setErrorMessage(error.message);
            } else {
                // Handle the case when error is not an Error instance
                setErrorMessage('An error occurred.');
            }
        }
    };

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        setErrorMessage(error?.message ? error?.message : '');
    }, [error]);

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <div id='dialog-root'></div>
            {/* {showTermsDialog && (
        <TermsDialog
          label={t('signup-card-agree-terms-conditions')}
          buttonTitle={t('signup-card-terms-button-title')}
          dialogTitle={t('terms-conditions-card-title')}
          content={<TermsConditionsContent />}
        />
      )} */}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    minWidth: 360,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                <GoogleSigninButton onSuccess={handleSubmitGoogle} />

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        margin: '0px 0',
                    }}>
                    <hr
                        style={{
                            flexGrow: 1,
                            borderTop: '1px solid silver',
                        }}
                    />
                    <span
                        style={{
                            margin: '0 10px',
                        }}>
                        {t('sign-in-or')}
                    </span>
                    <hr
                        style={{
                            flexGrow: 1,
                            borderTop: '1px solid silver',
                        }}
                    />
                </div>

                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        rememberMe: false,
                    }}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                    validationSchema={SignInRules}>
                    {({ errors, handleChange, values }) => (
                        <Form>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '24px',
                                    minWidth: 360,
                                }}>
                                <Box>
                                    <FormControl variant='standard' fullWidth>
                                        <InputLabel
                                            shrink
                                            htmlFor='email'
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#533D18',
                                            }}>
                                            <Typography>{t('userNameOrEmail')}</Typography>
                                        </InputLabel>
                                        <BootstrapStyleInput id='email' name='email' onChange={handleChange} fullWidth placeholder={t('enterUserNameOrEmail')} />
                                        {errors?.email && <Box sx={{ color: 'red' }}>{errors.email ? <div>{errors.email}</div> : null}</Box>}
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl variant='standard' fullWidth>
                                        <InputLabel
                                            shrink
                                            htmlFor='password'
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#533D18',
                                            }}>
                                            {t('password')}
                                        </InputLabel>
                                        <BootstrapStyleInput
                                            type={showPassword ? 'text' : 'password'} // Toggle between text and password type based on showPassword state
                                            id='password'
                                            name='password'
                                            onChange={handleChange}
                                            fullWidth
                                            inputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                                                            {showPassword ? <RemoveRedEyeOutlined /> : <RemoveRedEye />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#858585',
                                            }}>
                                            {t('must8Characters')}
                                        </FormHelperText>
                                        {errors?.password && <Box sx={{ color: 'red' }}>{errors.password ? <div>{errors.password}</div> : null}</Box>}
                                    </FormControl>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}>
                                    <FormControl>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.rememberMe}
                                                    onChange={(e) => {
                                                        handleChange(e); // Update the form value
                                                        setRememberMe(e.target.checked); // Update the UserAuthContext
                                                    }}
                                                    name='rememberMe'
                                                />
                                            }
                                            label={t('remember30Days')}
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#533D18',
                                            }}
                                        />
                                    </FormControl>
                                    <Typography variant='body2'>
                                        <Link
                                            to={`../${ForgotPasswordPath}?fallback=${fallbackPath}`}
                                            style={{
                                                textDecoration: 'none',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#F06E3C',
                                            }}>
                                            {t('forgot-password')}?
                                        </Link>
                                    </Typography>
                                </Box>
                                <Box>
                                    <LoadingButton
                                        variant='contained'
                                        disableElevation
                                        fullWidth
                                        size='large'
                                        color='primary'
                                        sx={{
                                            textTransform: 'none',
                                            borderRadius: '12px',
                                            background: '#F06E3C',
                                            boxShadow: '0px 4px 4px rgba(37, 37, 37, 0.35)',
                                            fontWeight: '500',
                                            lineHeight: '28px',
                                            fontSize: '18px',
                                            color: '#FDFCFC',
                                        }}
                                        type='submit'>
                                        {t('login-card-submit-button-title')}
                                    </LoadingButton>
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </GoogleOAuthProvider>
    );
};
